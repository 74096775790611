const panelConfig = {
  /**
   Production
   */
  API_URL: "https://blockmerchant-api.kryptomind.net",
  // API_URL: "https://testnet.blockmerchants.app",
  // API_URL: "https://blockmerchant-api.kryptomind.net/",

  //  API_URL: "https://4a1e-101-53-234-165.ngrok.io",
  /**
   Development
   */
  // API_URL: "http://localhost:4000",
};

export default panelConfig;
