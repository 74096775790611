import React, { useEffect, useState } from "react";
import styles from "./ShopCategory.module.scss";
import { Table, Spin } from "antd";
import AddShopCategoryModal from "../Modals/AddShopCategory/AddShopCategory";
import instance from "../../utils/helper/http.helper";
import karaConfig from "../../panel.config";
const ShopCategory = () => {
  const API_URL = karaConfig.API_URL;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [Data, setData] = useState();
  const ShowCategoryModal = () => {
    setIsOpen(!isOpen);
  };
  const FetchData = async () => {
    setLoading(true);
    try {
      const res = await instance.get(`${API_URL}/shop-category/get-all`);
      setData(res?.data);
      setLoading(false);
    } catch (error) {
      alert(error?.data?.response?.message);
    }
  };

  useEffect(() => {
    FetchData();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <div className={styles.title}>Shop Category</div>
          <div className={styles.addCategory}>
            <button onClick={ShowCategoryModal}>Add Category</button>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.wrapper}>
          {loading ? (
            <Spin size="large" />
          ) : (
            <Table
              //  loading={loading ? tableLoading : false}
              columns={columns}
              //  pagination={(state.pageCount, state.page)}
              scroll={{ x: true }}
              dataSource={Data}
            />
          )}
        </div>
      </div>

      <AddShopCategoryModal isOpen={isOpen} toggle={ShowCategoryModal} />
    </>
  );
};

export default ShopCategory;
