import React, { useEffect, useState } from "react";
import styles from "./InvoiceDetails.module.scss";
import instance from "../../utils/helper/http.helper";
import karaConfig from "../../panel.config";

import { useParams } from "react-router-dom";
import moment from "moment";
// import instance from "../../utils/helper/http.helper";

const InvoiceDetails = () => {
  const { id } = useParams();
  const [laoding, setLoading] = useState(false);
  const [Detail, setDetail] = useState();
  const API_URL = karaConfig.API_URL;

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const res = await instance.get(`${API_URL}/customer/getCustomer/${id}`);
      if (Object.keys(res?.data).length === 0) {
        setDetail([]);
      } else {
        setDetail([res?.data]);
      }
      setLoading(false);
    } catch (error) {
      setDetail([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <span>BlockMerchant</span>
          </div>
          <div className={styles.divider} />

          {laoding ? (
            <h3>Loading...</h3>
          ) : Detail?.length == 0 ? (
            <p>"No Invoice Details Available"</p>
          ) : (
            Detail?.map((item) => (
              <div className={styles.body}>
                <div className={styles.invoicedetail}>
                  <div className={styles.heading}>
                    <span>Invoice Details</span>
                  </div>
                  <div className={styles.card}>
                    <img src={item?.licenseImage} alt="Not Available" />
                  </div>
                </div>
                <div className={styles.customer}>
                  <label>Customer</label>
                  <div className={styles.name}>
                    <label>Name</label>
                    <span>
                      {item?.firstName ? item?.firstName : "-"}&nbsp;{" "}
                      {item?.lastName ? item?.lastName : "-"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Phone</label>
                    <span>{item?.phone ? item?.phone : "- -"}</span>
                  </div>
                  <div className={styles.name}>
                    <label>Email</label>
                    <span>{item?.email ? item?.email : "- -"}</span>
                  </div>
                  <div className={styles.name}>
                    <label>Created At</label>
                    <span>
                      {moment(item?.createdAt ? item?.createdAt : "- -").format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Updated At</label>
                    <span>
                      {moment(item?.updatedAt ? item?.updatedAt : "- -").format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </span>
                  </div>
                </div>
                {item?.products?.map((currentelm) => (
                  <div className={styles.customer}>
                    <label>Products</label>
                    <div className={styles.name}>
                      <label>Title</label>
                      <span>
                        {currentelm?.title ? currentelm?.title : "- -"}
                      </span>
                    </div>
                    <div className={styles.name}>
                      <label>Amount (Inc tax);</label>
                      <span>{currentelm?.tax ? currentelm?.tax : "- -"}</span>
                    </div>
                    <div className={styles.name}>
                      <label>Category</label>
                      <span>
                        {currentelm?.category ? currentelm?.category : "- -"}
                      </span>
                    </div>
                    <div className={styles.name}>
                      <label>MerchantId</label>
                      <span>
                        {currentelm?.merchantId
                          ? currentelm?.merchantId
                          : "- -"}
                      </span>
                    </div>
                    <div className={styles.name}>
                      <label>Price</label>
                      <span>
                        {currentelm?.price ? currentelm?.price : "- -"}
                      </span>
                    </div>
                    <div className={styles.name}>
                      <label>ShopId</label>
                      <span>
                        {currentelm?.shopId ? currentelm?.shopId : "- -"}
                      </span>
                    </div>
                    <div className={styles.name}>
                      <label>Created At</label>
                      <span>
                        {" "}
                        {moment(
                          currentelm?.createdAt ? currentelm?.createdAt : "- -"
                        ).format("MMMM Do YYYY, h:mm:ss a")}
                      </span>
                    </div>
                    <div className={styles.name}>
                      <label>Updated At</label>
                      <span>
                        {" "}
                        {moment(
                          currentelm?.updatedAt ? currentelm?.updatedAt : "- -"
                        ).format("MMMM Do YYYY, h:mm:ss a")}
                      </span>
                    </div>
                  </div>
                ))}
                <div className={styles.customer}>
                  <label>Merchant</label>
                  <div className={styles.name}>
                    <label>Name</label>
                    <span>
                      {item?.merchantId?.firstName
                        ? item?.merchantId?.firstName
                        : "-"}
                      &nbsp;
                      {item?.merchantId?.lastName
                        ? item?.merchantId?.lastName
                        : "-"}{" "}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Phone</label>
                    <span>
                      {item?.merchantId?.phoneNumber
                        ? item?.merchantId?.phoneNumber
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Email</label>
                    <span>
                      {item?.merchantId?.email
                        ? item?.merchantId?.email
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Address</label>
                    <span>
                      {item?.merchantId?.address
                        ? item?.merchantId?.address
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Wallet Address</label>
                    <span>
                      {item?.merchantId?.walletAddress
                        ? item?.merchantId?.walletAddress
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Created At</label>
                    <span>
                      {moment(
                        item?.merchantId?.createdAt
                          ? item?.merchantId?.createdAt
                          : "- -"
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Updated At</label>
                    <span>
                      {moment(
                        item?.merchantId?.updatedAt
                          ? item?.merchantId?.updatedAt
                          : "- -"
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    </span>
                  </div>
                </div>
                <div className={styles.customer}>
                  <label>Shop</label>
                  <div className={styles.name}>
                    <label>Name</label>
                    <span>
                      {item?.merchantId?.shopId?.name
                        ? item?.merchantId?.shopId?.name
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Phone</label>
                    <span>
                      {item?.merchantId?.shopId?.phone
                        ? item?.merchantId?.shopId?.phone
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Address</label>
                    <span>
                      {item?.merchantId?.shopId?.address
                        ? item?.merchantId?.shopId?.address
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>MerchantId</label>
                    <span>
                      {item?.merchantId?.shopId?.merchantId
                        ? item?.merchantId?.shopId?.merchantId
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Hours</label>
                    <span>
                      {item?.merchantId?.shopId?.hours
                        ? item?.merchantId?.shopId?.hours
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Category</label>
                    <span>
                      {item?.merchantId?.shopId?.category
                        ? item?.merchantId?.shopId?.category
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Website</label>
                    <span>
                      {item?.merchantId?.shopId?.website
                        ? item?.merchantId?.shopId?.website
                        : "- -"}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Location</label>
                    <span>
                      {item?.merchantId?.shopId?.location ? (
                        <a
                          href="https://www.google.com/maps/dir///@31.6651054,75.2628522,9z"
                          target="_blank"
                        >
                          Location
                        </a>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Created At</label>
                    <span>
                      {moment(
                        item?.merchantId?.shopId?.createdAt
                          ? item?.merchantId?.shopId?.createdAt
                          : "- -"
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    </span>
                  </div>
                  <div className={styles.name}>
                    <label>Updated At</label>
                    <span>
                      {moment(
                        item?.merchantId?.shopId?.updatedAt
                          ? item?.merchantId?.shopId?.updatedAt
                          : "- -"
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    </span>
                  </div>
                </div>
                <div className={styles.customer}>
                  <label>Coin</label>
                  <div className={styles.name}>
                    <label>Asset Used</label>
                    <span>{item?.assetUsed ? item?.assetUsed : "- -"}</span>
                  </div>
                  <div className={styles.name}>
                    <label>Asset Price</label>
                    <span>{item?.assetPrice ? item?.assetPrice : "- -"}</span>
                  </div>
                  <div className={styles.name}>
                    <label>Amount</label>
                    <span>
                      $&nbsp;{item?.usdAmount ? item?.usdAmount : "0"}
                    </span>
                  </div>
                </div>
                <div className={styles.customer}>
                  <label>Sale Info</label>
                  <div className={styles.name}>
                    <label>Sale Type</label>
                    <span>
                      {item?.products?.length > 0
                        ? "Cart Checkout"
                        : "Direct Invoice"}
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceDetails;
