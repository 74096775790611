import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import instance from "../../utils/helper/http.helper";
const Export = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    ExportFile();
  }, []);

  const ExportFile = async () => {
    try {
      setLoading(true);
      const res = await instance.post(`/customer/export/${id}`, null, {
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(new Blob([res.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      const fileName = "Sales-History.xlsx";
      fileLink.setAttribute("download", fileName);
      fileLink.setAttribute("target", "_blank");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return <>{loading ? <p>Please Wait...</p> : <></>}</>;
};

export default Export;
