export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const SET_ADMIN_PANEL_DATA = "SET_ADMIN_PANEL_DATA";

export const DEPOSIT_ADDRESSES_REQUEST = "DEPOSIT_ADDRESSES_REQUEST";
export const DEPOSIT_ADDRESSES_SUCCESS = "DEPOSIT_ADDRESSES_SUCCESS";
export const DEPOSIT_ADDRESSES_FAILURE = "DEPOSIT_ADDRESSES_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const ADD_NEW_TOKEN_REQUEST = "ADD_NEW_TOKEN_REQUEST";
export const ADD_NEW_TOKEN_SUCCESS = "ADD_NEW_TOKEN_SUCCESS";
export const ADD_NEW_TOKEN_FAILURE = "ADD_NEW_TOKEN_FAILURE";
export const ADD_NEW_TOKEN_RESET = "ADD_NEW_TOKEN_RESET";

export const CHANGE_APFEE = "CHANGE_APFEE";

export const CHANGE_APFEE_REQUEST = "CHANGE_APFEE_REQUEST";
export const CHANGE_APFEE_SUCCESS = "CHANGE_APFEE_SUCCESS";
export const CHANGE_APFEE_FAILURE = "CHANGE_APFEE_FAILURE";

export const SET_MERCHANT_SETTINGS = "SET_MERCHANT_SETTINGS";
