import React, { useState } from "react";
import styles from "./add-shop-category.module.scss";
import GenericModal from "../../_common/genericModal/index";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AddTechnologySchema } from "./AddShopCategory.schema";
import instance from "../../../utils/helper/http.helper";
import karaConfig from "../../../panel.config";
const AddShopCategory = ({ isOpen, toggle }) => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    addshopcategory: "",
  };
  const API_URL = karaConfig.API_URL;
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = { name: values?.addshopcategory };
      await instance.post(`${API_URL}/shop-category/create`, data);
      toggle();
      setLoading(false);
    } catch (error) {
      alert(error?.data?.response?.message);
      setLoading(false);
    }
  };
  return (
    <>
      <GenericModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainheading}>Add New ShopCategory</div>
        <Formik
          initialValues={initialValues}
          validationSchema={AddTechnologySchema}
          onSubmit={handleSubmit}
        >
          <Form className={styles.form}>
            <div className={styles.inputDiv}>
              <Field
                type="text"
                placeholder="Add Technology"
                name="addshopcategory"
              />
            </div>
            <ErrorMessage name="addshopcategory">
              {(errorText) => <div className={styles.error}>{errorText}</div>}
            </ErrorMessage>
            <button className={styles.button} disabled={loading} type="submit">
              {loading ? "Loading..." : "Add ShopCategory"}
            </button>
          </Form>
        </Formik>
      </GenericModal>
    </>
  );
};

export default AddShopCategory;
